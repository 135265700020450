/* eslint-disable no-console */

import { register } from 'register-service-worker'
import store from './store'

self.addEventListener("push", event => {
  const data = event.data.json()
  let options = {}
  if (data.body) options.body = data.body
  if (data.icon) options.icon = data.icon
  if (data.tag) options.tag = data.tag
  if (data.badge) options.badge = data.badge
  if (data.image) options.image = data.image
  if (data.data) options.data = data.data
  if (data.actions) options.actions = data.actions
  if (data.requireInteraction) options.requireInteraction = data.requireInteraction
  if (data.renotify) options.renotify = data.renotify
  if (data.silent) options.silent = data.silent
  if (data.timestamp) options.timestamp = data.timestamp
  if (data.vibrate) options.vibrate = data.vibrate
  if (data.dir) options.dir = data.dir
  if (data.lang) options.lang = data.lang

  self.registration.showNotification(data.title, options)
});

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}sw.js`, {
    ready (reg) {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      );
      reg.pushManager.getSubscription()
      .then(sub => {
        if (!sub) {
          store.commit('setSubscribed', false);
          return console.log("Not subscribed to push notifications");
        }
        store.commit('setSubscribed', true);
        store.dispatch('getSubscription');
      });
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}