<template>
  <section class="setting">
    <span>Nastavení notifikací:</span>
    <p class="error" v-if="!serviceWorkerPresent">Váš prohlížeč notifikace nepodporuje.</p>
    <template v-else-if="serviceWorkerPresent && !subscribed">
      <p v-if="!grade" class="alert">Pro zapnutí notifikací je nutnost mít nastavenou zvýrazněnou třídu.</p>
      <div class="table-layout">
        <div class="col">
          <div class="item">
            Třída
          </div>
          <div class="item">
            Večerní notifikace
          </div>
          <div class="item">
            Ranní notifikace
          </div>
        </div>
        <div class="col">
          <div class="item">
            <input type="text" v-model="notifyGrade" placeholder="Není nastavena!" :disabled="!grade" class="grow">
          </div>
          <div class="item">
            <Select v-model="eveningTime" :disabled="!grade" :options="eveningTimes" grow />
          </div>
          <div class="item">
            <Select v-model="morningTime" :disabled="!grade" :options="morningTimes" grow />
          </div>
        </div>
      </div>
      <button
        :disabled="!grade" 
        @click="subscribe"
        v-html="subscribing ? `<i class='fas fa-circle-notch fa-spin'></i>`  : 'Zapnout notifikace'"
      ></button>
    </template>
    <template v-else-if="serviceWorkerPresent && subscribed">
      <div class="table-layout">
        <div class="col">
          <div class="item">
            Třída
          </div>
          <div class="item">
            Večerní notifikace
          </div>
          <div class="item">
            Ranní notifikace
          </div>
        </div>
        <div class="col">
          <div class="item">
            <input type="text" v-model="notifyGrade" :class="{ modified: notifyGrade !== subscription.grade}" :placeholder="grade" class="grow">
          </div>
          <div class="item">
            <Select v-model="eveningTime" :modified="eveningTime !== subscription.eveningTime" :options="eveningTimes" grow />
          </div>
          <div class="item">
            <Select v-model="morningTime" :modified="morningTime !== subscription.morningTime" :options="morningTimes" grow />
          </div>
        </div>
      </div>
      <div class="group">
        <button 
          @click="unsubscribe"
          v-html="unsubscribing ? `<i class='fas fa-circle-notch fa-spin'></i>`  : 'Vypnout notifikace'"
        ></button>
        <button
          @click="update"
          :disabled="!subscriptionModified"
          v-html="updating ? `<i class='fas fa-circle-notch fa-spin'></i>`  : 'Uložit změny'"
        >
        </button>
      </div>
    </template>
  </section>
</template>

<script>
import axios from "axios";
import convertVapidKey from 'convert-vapid-public-key';
export default {
  data: () => ({
    publicKey: "BMwYFHTR2QOjaQ-8_Cmgte1J0LvSKS9bPVVCeJwO2YRXxUdf2sOE89z9rpwRAKebZLpn5Hl7oZ0CZ_kqo9-pn7Y",
    serviceWorkerPresent: null,
    subscribing: false,
    unsubscribing: false,
    updating: false,
    eveningTime: null,
    morningTime: null,
    eveningTimes: ["18:00", "19:00", "20:00", "21:00"],
    morningTimes: ["05:45", "06:00", "06:15", "06:30", "06:45", "07:00", "07:15"],
    notifyGrade: null,
  }),
  computed: {
    grade() {
      const grade = this.$store.state.grade;
      if (!this.subscription) {
        this.notifyGrade = grade;
      }
      return grade;
    },
    subscribed() {
      return this.$store.state.subscribed;
    },
    subscription() {
      return this.$store.state.subscription;
    },
    subscriptionModified() {
      return this.notifyGrade !== this.subscription.grade || this.eveningTime !== this.subscription.eveningTime || this.morningTime !== this.subscription.morningTime;
    },
  },
  created() {
    if (this.subscription) {
      this.eveningTime = this.subscription.eveningTime;
      this.morningTime = this.subscription.morningTime;
      this.notifyGrade = this.subscription.grade;
    }
  },
  mounted() {
    this.serviceWorkerPresent = "serviceWorker" in navigator;
  },
  methods: {
    subscribe() {
      if (this.subscribed || !this.grade) return;
      let grade = this.notifyGrade;
      let eveningTime = this.eveningTime;
      let morningTime = this.morningTime;
      let applicationServerKey = convertVapidKey(this.publicKey);
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(async (reg) => {
          const subscription = await reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey
          })
          this.subscribing = true;
          axios.post("https://api.michalwinter.cz/6574/v2/subscription", { subscription, grade, eveningTime, morningTime })
          .then((response) => {
            this.$store.commit("setSubscribed", true);
            this.$store.commit("setSubscription", response.data.subscription);
            this.subscribing = false;
          })
        });
      } else {
        console.log("Service worker not supported");
      }
    },
    unsubscribe() {
      if (!this.subscribed) return;
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(async (reg) => {
          const subscription = await reg.pushManager.getSubscription();
          if (subscription) {
            axios.delete("https://api.michalwinter.cz/6574/v2/subscription", { params: { id: this.subscription.id } })
            .then(async () => {
              await subscription.unsubscribe();
              this.$store.commit("setSubscribed", false);
              this.$store.commit("setSubscription", null);
              this.eveningTime = null;
              this.morningTime = null;
            })
          }
        });
      }
    },
    update() {
      if (!this.subscriptionModified) return;
      let grade = this.notifyGrade;
      let eveningTime = this.eveningTime;
      let morningTime = this.morningTime;
      axios.put("https://api.michalwinter.cz/6574/v2/subscription", { id: this.subscription.id, grade, eveningTime, morningTime })
      .then((response) => {
        this.$store.commit("setSubscription", response.data.subscription);
        this.updating = false;
      })
    }
    
  }
}
</script>