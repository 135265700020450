<template>
  <div id="body" :class="{ dark }">
    <article class="update" v-show="seenUpdate !== version">
      <div class="update-clickout" @click="seenUpdate = version"></div>
      <div class="update-content">
        <div class="header">
          <p>Verze {{ version }} - Novinky</p>
          <button @click="seenUpdate = version">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="content">
          <strong>Notifikace!</strong>
          <p>
            Nastavte si notifikace a dostávejte pravidelný přehled o tom jestli vám náhodou něco neodpadlo,
            a vy byste si pak mohli užít příjemný delší spánek a nebo naopak jít dřív domů a dál se pokoušet získat konečně ten další rank v lolku.
          </p>
          <strong>Zvýraznění</strong>
          <p>Konečně se vám budou i zvýrazňovat suplování pro celý ročník! (MVOPy, DVOPy)</p>
          <strong>Více tříd</strong>
          <p>Od teď pokud bude v suplování více tříd najednou, budou všechny zvýrazněny.</p>
          <strong>Discord Server</strong>
          <p>
            Pro jakékoliv návrhy, problémy nebo bugy tu je od teď Discord server!<br>
            <a href="https://discord.gg/hcC3bc2ZYd" target="__blank">https://discord.gg/hcC3bc2ZYd</a>
          </p>
        </div>
      </div>
    </article>
    <article class="update" v-show="updateDetails">
      <div class="update-clickout" @click="updateDetails = false"></div>
      <div class="update-content">
        <div class="header">
          <p>Pravidelnost aktualizací</p>
          <button @click="updateDetails = false">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="content">
          <p>Suplování je automaticky kontrolováno serverem v určité časy přes webové stránky školy.</p>
          <strong>Časování:</strong>
          <p>Školní dny: <br>- 5:00-6:00 - každých 20 minut<br>- 6:00-8:00 - každých 5 minut<br>- 8:00-22:00 - každých 20 minut</p>
          <p>Víkendy a všední dny během prázdnin: <br> - každou 4. hodinu dne (4:00, 8:00...)</p>
        </div>
      </div>
    </article>
    <header>
      <button @click="dark = !dark">
        <i v-if="!dark" class="fas fa-moon"></i>
        <i v-else class="fas fa-lightbulb"></i>
      </button>
      <h1 v-text="settings ? 'Nastavení' : 'SSPŠaG Suplování'"></h1>
      <div class="group">
        <button @click="seenUpdate = null">
          <i class="fas fa-info"></i>
        </button>
        <button @click="settings = !settings; settingsNew = newInSettings">
          <i v-if="!settings" class="fas fa-cog">
            <div class="new-dot" v-if="!settingsNew.includes(newInSettings)"></div>
          </i>
          <i v-else class="fas fa-times"></i>
        </button>
      </div>
    </header>
    <main v-if="!settings">
      <section class="info">
        <p>Suplování je pravidelně kontrolováno serverem. <a @click="updateDetails = true">(Detail)</a></p>
      </section>
      <section v-if="today" class="day">
        <h2>Dnes, {{ getCzechDate(today.date) }}</h2>
        <div class="events">
          <!--<div class="event" v-for="(event, i) in today.list" :key="i">
            <div class="grade" :class="{ highlight: compareGrade(event.grade) }">
              <span>{{ event.grade || '-' }}</span>
            </div>
            <h3>{{ event.text }}</h3>
          </div>-->
          <Event v-for="(event, i) in today.list" :key="i" :event="event" />
        </div>
      </section>
      <section v-else class="day">
        <h2>Dnes, {{ todayDate }}</h2>
        <p class="none">Žádné suplování na tento den.</p>
      </section>
      <section class="day" v-if="tomorrow">
        <h2>Zítra, {{ getCzechDate(tomorrow.date) }}</h2>
        <div class="events">
          <Event v-for="(event, i) in tomorrow.list" :key="i" :event="event" />
        </div>
      </section>
      <section class="day" v-for="(day, i) in upcomingDays" :key="i">
        <h2>{{ getCzechDate(day.date) }}</h2>
        <div class="events">
          <Event v-for="(event, i) in day.list" :key="i" :event="event" />
        </div>
      </section>
    </main>
    <main v-else>
      <section class="setting">
        <span>Zvýrazněná třída:</span>
        <p class="alert" v-show="newGrade !== grade">Upraveno. Musí se uložit!</p>
        <input type="text" v-model="newGrade" :class="{ modified: newGrade !== grade}" :placeholder="grade ? grade : 'Třída pro zvýraznění'">
        <button :disabled="newGrade === grade" @click="saveGrade">Uložit</button>
      </section>
      <SettingNotification />
    </main>
  </div>
</template>

<script>
import moment from "moment";
import { version } from "../package.json";
import SettingNotification from "./components/SettingNotification.vue";
import Event from "./components/Event.vue";

export default {
  name: "App",
  components: {
    SettingNotification,
    Event
  },
  data: () => ({
    standalone: false,
    settings: false,
    newGrade: "",
    todayDate: "",
    updateDetails: false,
    newInSettings: "notifications",
    version,
  }),
  mounted() {
    this.$store.dispatch("getData");
    let todayDate = moment().locale("cs").format("dddd D. M. YYYY")
    this.todayDate = todayDate.charAt(0).toUpperCase() + todayDate.slice(1);
    this.newGrade = this.$store.state.grade || "";
  },
  computed: {
    dark: {
      get() {
        return this.$store.state.dark;
      },
      set(value) {
        this.$store.commit("setDark", value);
      },
    },
    seenUpdate: {
      get() {
        return this.$store.state.seenUpdate;
      },
      set(value) {
        this.$store.commit("setSeenUpdate", value);
      },
    },
    settingsNew: {
      get() {
        return this.$store.state.settingsNew;
      },
      set(value) {
        if (!this.settingsNew.includes(value))
          this.$store.commit("addSettingsNew", value);
      },
    },
    grade: {
      get() {
        return this.$store.state.grade;
      },
      set(value) {
        this.$store.commit("setGrade", value);
      },
    },
    today() {
      return this.$store.state.days.find((day) => {
        return moment(day.date).isSame(moment(), "day");
      });
    },
    tomorrow() {
      //Check if tomorrow is a weekend
      if (moment().add(1, "days").isoWeekday() > 5) return false;
      return this.$store.state.days.find((day) => {
        return moment(day.date).isSame(
          moment().add(1, "day"),
          "day"
        );
      });
    },
    upcomingDays() {
      // upcoming days excluding today and tomorrow
      return this.$store.state.days.filter((day) => {
        return (
          moment(day.date).isAfter(moment(), "day") &&
          moment(day.date).isAfter(moment().add(1, "days"), "day")
        );
      });
    },
  },
  methods: {
    getCzechDate(inputDate) {
      let date = moment(inputDate).locale("cs").format("dddd D. M. YYYY");
      return date.charAt(0).toUpperCase() + date.slice(1);
    },
    saveGrade() {
      if (this.newGrade === this.grade) return;
      this.$store.commit("setGrade", this.newGrade);
    },
    compareGrade(grade) {
      return grade === this.grade || grade === (this.grade[0] + ".r");
    },
  },
};
</script>