<template>
  <div class="select" :class="{ grow }">
    <div class="select-popout-wrap" v-if="opened">
      <div class="select-popout-clickout" @click="opened = false"></div>
      <div class="select-popout">
        <div class="select-popout-option" @click="select(null)">
          <i
            :class="{
              'fas fa-check-circle': selected.value === null,
              'far fa-circle': selected.value !== null
            }"
          ></i>
          <div class="select-input-label"> {{ nullLabel || "Vypnuto"}} </div>
        </div>
        <div class="select-popout-option" v-for="option in Options" :key="option.value" @click="select(option.value)">
          <i
            :class="{
              'fas fa-check-circle': option.value === selected.value,
              'far fa-circle': option.value !== selected.value
            }"
          ></i>
          <div class="label"> {{option.label}} </div>
        </div>
      </div>
    </div>
    <div class="select-input" :class="{ disabled, modified }" @click="open">
      <div
        class="select-input-label"
        v-text="selected.label"
      />
      <i class="fas fa-chevron-down"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    nullLabel: {
      type: String,
      default: 'Vypnuto',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    grow: {
      type: Boolean,
      default: false,
    },
    modified: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    opened: null,
  }),
  computed: {
    selected() {
      return this.Options.find(option => option.value === this.value) || {
        value: null,
        label: this.nullLabel,
      };
    },
    Options() {
      return this.options.map(option => {
        if (typeof option === 'string')
          return {
            value: option,
            label: option,
          };
        return option;
      });
    },
  },
  methods: {
    select(value) {
      this.$emit("input", value);
      this.opened = false;
    },
    open() {
      if (this.disabled) return;
      this.opened = true;
    }
  }
}
</script>