import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import axios from 'axios'

const apiUrl = (url) => 
  "https://api.michalwinter.cz/6574/v2" + 
  url +
  (process.env.NODE_ENV === 'production' ? '' : '?dev=true')


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    seenUpdate: null,
    dark: false,
    grade: "",
    days: [],
    subscribed: null,
    subscription: null,
    settingsNew: [],
  },
  mutations: {
    setDark(state, dark) {
      state.dark = dark
    },
    setGrade(state, grade) {
      state.grade = grade
    },
    setDays(state, days) {
      state.days = days
    },
    setSeenUpdate(state, seenUpdate) {
      state.seenUpdate = seenUpdate
    },
    setSubscribed(state, subscribed) {
      state.subscribed = subscribed
    },
    setSubscription(state, subscription) {
      state.subscription = subscription
    },
    addSettingsNew(state, settingsNew) {
      state.settingsNew.push(settingsNew)
    }
  },
  actions: {
    getData({ commit }) {
      axios.get(apiUrl("/suplovani"))
        .then(res => {
          console.log(res.data);
          commit('setDays', res.data);
        })
    },
    getSubscription({ commit, state }) {
      if (!state.subscription) return;
      axios.get(apiUrl("/subscription"), { params: { id: state.subscription.id } })
        .then(res => {
          commit('setSubscription', res.data);
        })
        .catch(err => {
          //Check if error status code is 401
          if (err.response.status === 401) {
            //If so, remove the subscription
            commit('setSubscription', null);
            commit('setSubscribed', false);
            //Unsubscribe to push notifications in browser from service worker
            navigator.serviceWorker.getRegistration().then(reg => {
              reg.pushManager.getSubscription().then(sub => {
                sub.unsubscribe().then(() => {
                  console.log("Unsubscribed to push notifications");
                });
              });
            });
          }
        })
    }
  },
  modules: {
  },
  plugins: [
    new VuexPersist({
      key: 'sablovo-uloziste',
      reducer: (state) => ({
        dark: state.dark,
        grade: state.grade,
        days: state.days,
        seenUpdate: state.seenUpdate,
        settingsNew: state.settingsNew,
        subscription: state.subscription,
      }),
    }).plugin,
  ],
})
