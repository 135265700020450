import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'

Vue.config.productionTip = false

import "./assets/scss/main.scss";


import Select from "./components/elements/Select.vue";

Vue.component('Select', Select);

new Vue({
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
