<template>
  <div class="event">
    <div class="grades" v-if="event.grades">
      <div class="grade"
        v-for="(g, i) in event.grades" 
        :key="i"
        :class="{ highlight: compareGrade(g) }" 
      >
        <span>{{ g }}</span>
      </div>
    </div>
    <div class="grades" v-else>
      <div class="grade">
        <span>
          -
        </span>
      </div>
    </div>
    <h3>{{ event.text }}</h3>
  </div>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      required: true
    },
  },
  computed: {
    grade() {
      return this.$store.state.grade;
    },
  },
  methods: {
    compareGrade(grade) {
      return grade === this.grade || grade === (this.grade[0] + ".r");
    },
  }
}
</script>

<style>

</style>